<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>API Token</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="9">
				<v-text-field
					outlined
					dense
					v-model="token"
					:disabled="true"
					hide-details="true"
					label="Token" />
			</v-col>
			<v-col cols="3">
				<v-btn elevation="0" color="primary" @click="reset">
					{{ $t("labels.btnGenerateNew") }}
				</v-btn>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	data: () => ({
		token: "",
	}),
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			var token = await this.$store.dispatch("auth/getToken");
			this.token = token.value;
		},
		async reset() {
			var response = await this.$store.dispatch("dialog/show", {
				title: this.$t("labels.warning"),
				content: this.$t("alerts.generateNewToken"),
			});
			if (response) {
				var token = await this.$store.dispatch("auth/resetToken");
				this.token = token;
			}
		},
	},
};
</script>
