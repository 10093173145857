<template>
  <div class="text-center" style="padding: 50px">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>

export default {
  mounted() {
    if (this.$route.query.code) {
      this.checkCode(this.$route.query.code)
    } else {
      this.$router.push({ name: 'settings' })
    }
  },
  methods: {
    async checkCode(code) {
      const result = await this.$store.dispatch('box/authCallback', code)
      this.$router.push({ name: 'settings' })
    }
  }
}
</script>
