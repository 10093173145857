<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>
					{{ $t("pages.settings.subtitle.paymentMethods") }}
				</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn elevation="0" color="primary" @click="manage">
					{{ $t("labels.btnManage") }}
				</v-btn>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	methods: {
		manage() {
			this.$router.push({
				name: "settings-payment-methods-list",
			});
		},
	},
};
</script>
