<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>{{ $t("pages.settings.subtitle.boxAuth") }}</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" v-if="profile">
				Logado como:
				<strong>{{ profile.name }}</strong>
			</v-col>
			<v-col cols="12" v-else>
				<v-btn elevation="0" color="primary" @click="auth">
					{{ $t("labels.btnBoxAuth") }}
				</v-btn>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			await this.$store.dispatch("box/profileInfo");
		},
		async auth() {
			const result = await this.$store.dispatch("box/getAuthURL");
			if (result.status) {
				window.location.href = result.body.url;
			}
		},
	},
	computed: {
		profile() {
			return this.$store.getters["box/getProfile"];
		},
	},
};
</script>
