<template>
	<div>
		<f-default-header :title="$t('pages.settings.title')" />

		<f-payment-methods />
		<f-card-spacer />

		<f-demo-intervals />
		<f-card-spacer />

		<f-file-headers />
		<f-card-spacer />

		<f-job-roles />
		<f-card-spacer />

		<f-country />
		<f-card-spacer />

		<f-emails setting-name="supportEmails" />
		<f-card-spacer />

		<f-emails setting-name="mktContactEmails" />
		<f-card-spacer />

		<f-emails setting-name="orderEmails" />
		<f-card-spacer />

		<f-api-token />
		<f-card-spacer />

		<f-box-auth />
		<f-card-spacer />

		<f-vertical-activity-rel />
	</div>
</template>

<script>
import FPaymentMethods from "../../components/settings/paymentMethods/Widget.vue";
import FDemoIntervals from "../../components/settings/demoIntervals/Widget.vue";
import FFileHeaders from "../../components/settings/fileHeaders/Widget.vue";
import FJobRoles from "../../components/settings/jobRoles/Widget.vue";
import FCountry from "./../../components/settings/country/Widget.vue";
import FEmails from "../../components/settings/Emails.vue";
import FApiToken from "../../components/settings/ApiToken.vue";
import FBoxAuth from "../../components/settings/BoxAuth.vue";
import FVerticalActivityRel from "../../components/settings/verticalActivityRel/Widget.vue";

export default {
	components: {
		FPaymentMethods,
		FDemoIntervals,
		FFileHeaders,
		FJobRoles,
		FCountry,
		FEmails,
		FApiToken,
		FBoxAuth,
		FVerticalActivityRel,
	},
};
</script>
