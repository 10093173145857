<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>
					{{ $t("pages.settings.subtitle.countries") }}
				</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn elevation="0" color="primary" @click="manage">
					{{ $t("labels.btnManage") }}
				</v-btn>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
import { SETTINGS_ROUTES } from "./../../../router/settings";

export default {
	methods: {
		manage() {
			this.$router.push({
				name: SETTINGS_ROUTES.COUNTRY_LIST,
			});
		},
	},
};
</script>
