<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>
					{{ title }}
				</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn elevation="0" color="primary" @click="manage">
					{{ $t("labels.btnManage") }}
				</v-btn>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	props: {
		settingName: String,
	},
	methods: {
		manage() {
			this.$router.push({
				name: "setting-emails",
				params: {
					name: this.settingName,
				},
			});
		},
	},
	computed: {
		title() {
			const labels = {
				supportEmails: this.$t("pages.settings.subtitle.mktSupportEmails"),
				mktContactEmails: this.$t("pages.settings.subtitle.mktContactEmails"),
				orderEmails: this.$t("pages.settings.subtitle.mktOrdersEmails"),
			};
			return labels[this.settingName];
		},
	},
};
</script>
